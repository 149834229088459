<template>
  <div>
    <Slider :partner-id="partnerId" />
    <Breadcrumb class="container mt-4" />
    <div class="container tags-search">
      <div class="row flex-md-row-reverse">
        <div class="col-12 col-md-6">
          <Searchform v-model="terms" button-color="yellow" />
        </div>
        <div class="col-12 col-md-6 pt-4 pt-md-0">
          <Tags
            v-if="categories.length"
            :tags="categories"
            :selected="categoryId"
            value-field="code"
            text-field="title"
          >
            <template #tag="{ item }">
              <router-link :to="{ name: 'shops', query: { categoryId: item.code } }">
                {{ item.getTitle($l) }}
              </router-link>
            </template>
          </Tags>
        </div>
      </div>
    </div>
    <VilleCommercants :partner-id="partnerId" :category-id="categoryId" />
  </div>
</template>

<script>
import Slider from '@/components/Sliders/Slider.vue'
import VilleCommercants from '@/components/VilleCommercants/VilleCommercants.vue'
import Tags from '@/components/UI/Tags.vue'
import Searchform from '@/components/UI/Searchform.vue'
import Breadcrumb from '@/components/UI/Breadcrumb.vue'

export default {
  name: 'ViewVilleCommercants',
  components: {
    Slider,
    VilleCommercants,
    Tags,
    Searchform,
    Breadcrumb
  },
  props: {
    partnerId: { type: String }
  },
  data: () => ({
    terms: null
  }),
  computed: {
    partner() {
      return this.$store.getters['partner/current']
    },
    categories() {
      return this.$store.getters['partner/categories']
    },
    categoryId() {
      return this.$route.query.categoryId
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/youpaq.scss';

/* tags search */
.tags-search {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 30px;

  @include media-breakpoint-up(md) {
    justify-content: space-between;
  }

  @include media-breakpoint-up(lg) {
    margin: 50px auto 50px;
  }
}

/* component Slider */
#slider .agile .agile__nav-button {
  background: transparent !important;
  border: none;
  position: absolute;
  bottom: 0px;
  @include media-breakpoint-up(md) {
    bottom: 240px;
  }
  @include media-breakpoint-up(lg) {
    bottom: 325px;
  }
}
#slider .agile__actions {
  position: relative;
}
#slider .agile__nav-button--prev {
  display: none;
  left: calc(50vw - 150px);
  @include media-breakpoint-up(md) {
    display: flex;
    left: calc(50vw - 385px);
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    left: calc(50vw - 700px);
  }
}
#slider .agile__nav-button--next {
  display: none;
  left: calc(50vw + 90px);
  @include media-breakpoint-up(md) {
    display: flex;
    left: calc(50vw + 305px);
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    left: calc(50vw + 570px);
  }
}
#slider .agile__dots {
  position: absolute;
  left: calc(50vw - 53px);
  bottom: 0px;
}
#slider .agile__dots .agile__dot button{
  background-color: transparent;
  width: 15px !important;
  height: 15px !important;
  margin: 10px;
  background-size: contain !important;
  border: none;
  background: url("~@/assets/slider-dot-white.svg") no-repeat;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2)) !important;
  cursor: pointer;
}
#slider .agile__dot--current button{
  background: url("~@/assets/slider-dot-white-current.svg") no-repeat !important;
}
</style>
