<template>
  <div
    id="ville-commercants"
    class="container"
  >
    <div class="row">
      <div v-for="shop in shops" :key="shop.id" class="col-12 col-md-6 col-lg-3 mt-5">
        <VilleCommercantsItem
          :shop-id="shop.id"
          :shop-slug="shop.slug"
          :image="shop.imageUrl"
          :name="shop.name"
          :description="shop.getDescriptionShort($l)"
          :favourite.sync="shop.favourite"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VilleCommercantsItem from '@/components/VilleCommercants/VilleCommercantsItem.vue'
export default {
  name: 'VilleCommercants',
  components: {
    VilleCommercantsItem
  },
  props: {
    partnerId: { type: String },
    categoryId: { type: String }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    categories() {
      return this.$store.getters['partner/categories']
    },
    shops() {
      const shops = this.$store.getters['partner/shops']

      if (this.categoryId) {
        const category = this.categories.find(category => category.code === this.categoryId)
        if (!category) {
          return []
        }
        return category.shopIds
          .map(shopId => shops.find(s => s.id === shopId))
          .filter(shop => !!shop)
      }
      return shops
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      return Promise
        .all([
          this.$store.dispatch('partner/shops', { id: this.partnerId }),
          this.$store.dispatch('partner/categories', { id: this.partnerId })
        ])
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#ville-commercants {
  padding-bottom: 150px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 250px;
  }
}

.ville-commercant-item {
  background-color: var(--gray-color);
  padding-bottom: 70px;
  box-shadow: 2px 3px 6px #0000005e;
  margin: 0 30px;

  @include media-breakpoint-up(md) {
    margin: 0;
  }
}
.ville-commercant-item a {
  text-decoration: none;
}
.ville-commercant-item img {
  width: 100%;
}
h3 {
  font-size: 20px;
  font-weight: 900;
  color: white;
  margin: 30px 0 5px 0;
  padding: 0 30px;
  text-align: left;
}
p {
  color: white;
  font-weight: 300;
  margin: 10px 0;
  text-align: left;
  padding: 0 30px;
  font-size: 16px;
  line-height: 1.5rem;
}

</style>
